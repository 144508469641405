import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { DateInput, Link } from 'hds-react';
import ExternalLink from '../../../components/ExternalLink';
import Playground from '../../../components/Playground';
import TabsLayout from './tabs.mdx';
import InternalLink from '../../../components/InternalLink';
export const _frontmatter = {
  "slug": "/components/date-input/code",
  "title": "DateInput - Code"
};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const IconCheckCircleFill = makeShortcode("IconCheckCircleFill");
const IconCrossCircle = makeShortcode("IconCrossCircle");
const layoutProps = {
  _frontmatter
};
const MDXLayout = ({
  children,
  pageContext
}) => <TabsLayout pageContext={pageContext}>{children}</TabsLayout>;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h2 {...{
      "id": "code",
      "style": {
        "position": "relative"
      }
    }}>{`Code`}<a parentName="h2" {...{
        "href": "#code",
        "aria-label": "code permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h2>
    <h3 {...{
      "id": "code-examples",
      "style": {
        "position": "relative"
      }
    }}>{`Code examples`}<a parentName="h3" {...{
        "href": "#code-examples",
        "aria-label": "code examples permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <h4 {...{
      "id": "date-input-with-a-picker",
      "style": {
        "position": "relative"
      }
    }}>{`Date input with a picker`}<a parentName="h4" {...{
        "href": "#date-input-with-a-picker",
        "aria-label": "date input with a picker permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h4>
    <Playground mdxType="Playground">
      <pre><code parentName="pre" {...{
          "className": "language-jsx"
        }}>{`<div style={{maxWidth: '400px'}}>
  <DateInput 
    helperText="Use format D.M.YYYY"
    id="date-input-example-1"
    initialMonth={new Date()}
    label="Choose a date"
    language="en"
    onChange={function noRefCheck(){}}
    required
  />
</div>
`}</code></pre>
    </Playground>
    <h3 {...{
      "id": "date-input-without-a-confirmation",
      "style": {
        "position": "relative"
      }
    }}>{`Date input without a confirmation`}<a parentName="h3" {...{
        "href": "#date-input-without-a-confirmation",
        "aria-label": "date input without a confirmation permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <Playground mdxType="Playground">
      <pre><code parentName="pre" {...{
          "className": "language-jsx"
        }}>{`<div style={{maxWidth: '400px'}}>
  <DateInput
    disableConfirmation
    helperText="Use format D.M.YYYY"
    id="date-input-example-2"
    initialMonth={new Date()}
    label="Choose a date"
    language="en"
    onChange={function noRefCheck(){}}
    required
/>
</div>
`}</code></pre>
    </Playground>
    <h3 {...{
      "id": "date-input-without-a-picker",
      "style": {
        "position": "relative"
      }
    }}>{`Date input without a picker`}<a parentName="h3" {...{
        "href": "#date-input-without-a-picker",
        "aria-label": "date input without a picker permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <Playground mdxType="Playground">
      <pre><code parentName="pre" {...{
          "className": "language-jsx"
        }}>{`<div style={{maxWidth: '400px'}}>
  <DateInput
    disableDatePicker
    helperText="Use format D.M.YYYY"
    id="date-input-example-3"
    initialMonth={new Date()}
    label="Choose a date"
    language="en"
    onChange={function noRefCheck(){}}
    required
  />
</div>
`}</code></pre>
    </Playground>
    <h3 {...{
      "id": "localised-date-inputs",
      "style": {
        "position": "relative"
      }
    }}>{`Localised date inputs`}<a parentName="h3" {...{
        "href": "#localised-date-inputs",
        "aria-label": "localised date inputs permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <Playground mdxType="Playground">
      <pre><code parentName="pre" {...{
          "className": "language-jsx"
        }}>{`<div style={{maxWidth: '400px'}}>
  <DateInput 
    helperText="Use format D.M.YYYY"
    id="date-input-example-4"
    initialMonth={new Date()}
    label="Choose a date"
    language="en"
    onChange={function noRefCheck(){}}
    required
    style={{marginBottom: 'var(--spacing-s)'}}
  />

  <DateInput 
    helperText="Käytä muotoa P.K.VVVV"
    id="date-input-example-5"
    initialMonth={new Date()}
    label="Valitse päivämäärä"
    language="fi"
    onChange={function noRefCheck(){}}
    required
    style={{marginBottom: 'var(--spacing-s)'}}
  />

  <DateInput 
    helperText="Använd ett format D.M.ÅÅÅÅ"
    id="date-input-example-6"
    initialMonth={new Date()}
    label="Välj ett datum"
    language="sv"
    onChange={function noRefCheck(){}}
    required
  />
</div>
`}</code></pre>
    </Playground>
    <h3 {...{
      "id": "packages",
      "style": {
        "position": "relative"
      }
    }}>{`Packages`}<a parentName="h3" {...{
        "href": "#packages",
        "aria-label": "packages permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Package`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Included`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Storybook link`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Source link`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`HDS React`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}><div style={{
              display: 'flex',
              gap: 'var(--spacing-3-xs)'
            }}><IconCheckCircleFill mdxType="IconCheckCircleFill" />{` Yes `}</div></td>
          <td parentName="tr" {...{
            "align": null
          }}><Link openInNewTab openInNewTabAriaLabel="Opens in a new tab" href="/storybook/react/?path=/story/components-dateinput--default" mdxType="Link">{`View in Storybook`}</Link></td>
          <td parentName="tr" {...{
            "align": null
          }}><ExternalLink openInNewTab openInNewTabAriaLabel="Opens in a new tab" href="https://github.com/City-of-Helsinki/helsinki-design-system/blob/master/packages/react/src/components/dateInput/DateInput.tsx" mdxType="ExternalLink">{`View source`}</ExternalLink></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`HDS Core`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}><div style={{
              display: 'flex',
              gap: 'var(--spacing-3-xs)'
            }}><IconCrossCircle mdxType="IconCrossCircle" />{` No `}</div></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`-`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`-`}</td>
        </tr>
      </tbody>
    </table>
    <h3 {...{
      "id": "properties",
      "style": {
        "position": "relative"
      }
    }}>{`Properties`}<a parentName="h3" {...{
        "href": "#properties",
        "aria-label": "properties permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <p>{`Note! You can find the full list of properties in the `}<Link openInNewTab openInNewTabAriaLabel="Opens in a new tab" href="/storybook/react/?path=/story/components-dateinput--default" mdxType="Link">{`React Storybook.`}</Link></p>
    <p>{`Also, note that this component is an input. All features supported by the HDS TextInput are also supported by this component. See `}<InternalLink href="/components/text-input" mdxType="InternalLink">{`TextInput documentation page`}</InternalLink>{` for more information.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Property`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Values`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Default value`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`helperText`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Helper text to be displayed below the input.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`string`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`-`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`errorText`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Error text to be displayed below the input.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`string`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`-`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`successText`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Success text to be displayed below the input.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`string`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`-`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`infoText`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Info text to be displayed below the input.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`string`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`-`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`initialMonth`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The initial month open in calendar.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`Date`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`-`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`label`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`A text label for the input.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`string`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`-`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`onChange`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Callback fired when the state is changed.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`event`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`-`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`language`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Language of the date input.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`string`}</inlineCode>{` (fi, en, sv)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`en`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`minDate`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Minimum date to show in the datepicker calendar.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`Date`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Start of the month one year before current date`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`maxDate`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Maximum date to show in the datepicker calendar.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`Date`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`End of the month one year from current date.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`isDateDisabledBy`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Disables date(s) based on conditional function.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`function`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`-`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`initialMonth`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The initial month open in calendar.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`Date`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`-`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`disableDatePicker`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`If set to true, the date picker button is hidden.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`boolean`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`false`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`disableConfirmation`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`If set to true, the date picker does not require confirmation.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`boolean`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`false`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      